import TypedBroadcastChannel from 'o365.modules.TypedBroadcastChannel.ts';

type MessageType = 'App' | 'Database' | 'ObjectStore' | 'Index' | 'PWAState' | 'ServiceWorkerState' | 'SerivceWorkerScriptState' | 'AppResourceState' | 'User' | 'GlobalSetting' | 'CloseDatabase';

interface Message<T extends MessageType> {
    type: T;
    instanceKey: `${string}-${string}-${string}-${string}-${string}`;
}

interface IAppMessage extends Message<'App'> {
    appId: string;
}

interface IDatabaseMessage extends Message<'Database'> {
    appId: string;
    databaseId: string;
}

interface IObjectStoreMessage extends Message<'ObjectStore'> {
    appId: string;
    databaseId: string;
    objectStoreId: string;
}

interface IIndexMessage extends Message<'Index'> {
    appId: string;
    databaseId: string;
    objectStoreId: string;
    indexId: string;
}

interface IPWAStateMessage extends Message<'PWAState'> {
    appId: string;
}

interface IServiceWorkerStateMessage extends Message<'ServiceWorkerState'> {
    appId: string;
}

interface IServiceWorkerScriptStateMessage extends Message<'SerivceWorkerScriptState'> {
    appId: string;
    serviceWorkerScriptId: string;
}

interface IAppResourceStateMessage extends Message<'AppResourceState'> {
    appId: string;
    appResourceId: string;
}

interface ICloseDatabaseMessage extends Message<'CloseDatabase'> {
    appId: string;
    databaseId: string;
}

interface IUserMessage extends Message<'User'> {}

interface IGlobalSettingMessage extends Message<'GlobalSetting'> {}

type EventData = IAppMessage | IDatabaseMessage | IObjectStoreMessage | IIndexMessage | IPWAStateMessage | IServiceWorkerStateMessage | IServiceWorkerScriptStateMessage | IAppResourceStateMessage | IUserMessage | IGlobalSettingMessage | ICloseDatabaseMessage;

const broadcastChannelId = 'O365_PWA_INDEXED_DB_HANDLER_BROADCAST_CHANNEL';
const broadcastChannel = new TypedBroadcastChannel<EventData>(broadcastChannelId);

export default broadcastChannel;
